<template>
    <div class="choose-supplier">
        <div class="choose-supplier-title">
            <h2>בחירת ספק</h2>
        </div>
        <div class="choose-supplier-content">
                <el-select
                    v-model="selected_supplier"
                    class="m-2"
                    placeholder="בחירת ספק"
                    size="large"
                    filterable
                    clearable
                    style="width: 300px; display: block;"
                >
                    <el-option
                    v-for="supplier in suppliers_list"
                    :key="supplier.num"
                    :label="supplier.name + ` - (${supplier.num})`"
                    :value="supplier.num"
                    />
                </el-select>
                <el-button @click="handle_next" style="margin-top: 5px; width: 300px;" type="primary">המשך</el-button>
        </div>
    </div>
</template>

<script>
import {
    get_suppliers_list,
    suppliers_list,
    selected_supplier,
    get_items_by_supplier
} 
from '../client/store'
import {slide_pop_error} from '../../../../Methods/Msgs'
export default {
    emits:['next'],
    setup (_,{emit}) {
        

        const handle_next = async() => {
            try{
                if(!selected_supplier.value) throw new Error('עליך לבחור ספק כדי להמשיך!')
                await get_items_by_supplier(selected_supplier.value)
                emit('next','Catalog')
            }catch(err){
                slide_pop_error(err.message)
            }
        }

        const init = () => {
            try{
                get_suppliers_list()
            }catch(err){
                console.error(err.message)
            }
        }

        init()

        return {
            init,
            handle_next,
            suppliers_list,
            selected_supplier,
        }
    }
}
</script>

<style scoped>
    .choose-supplier{
        width: 100%;
        height: 100%;
    }
    .choose-supplier-title{
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .choose-supplier-content{
        width: 100%;
        height: calc(100% - 50px);
        display: flex;
        flex-direction: column;

        align-items: center;
    }
</style>